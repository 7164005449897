import store from '@tenant/core/init/store'
import { GUARD, USER_ACCOUNT_TYPES } from '@tenant/utils/constants'

const centralGuard = async (to, from, next) => {
    if (to.meta.guard === GUARD.ACCOUNTANT) {
        if (!store.getters.hasCentralToken) {
            next({ name: 'auth.login' })

            return
        }

        if (!store.getters.hasCentralRole(USER_ACCOUNT_TYPES.ACCOUNTANT)) {
            next('/')

            return
        }
    }

    next()
}

export default centralGuard
